const articles = [
	{
		title: "CREPE: A Convolutional Representation for Pitch Estimation",
		comment: "Scientific article",
		img: "/articles/CREPE-Pitch.png",
		href: "/comingsoon/",
		tag: "Machine learning",
		homepage: true
	},
	{
		title: "Let’s quit chatGPT and copilot for local LLM!",
		comment: "Install private and secure assistant",
		img: "/articles/Liama3.1-Article.png",
		href: "https://medium.com/@chloe.lavrat/lets-quit-chatgpt-and-copilot-how-to-install-your-own-llm-for-privacy-and-security-fa491b7d414c",
		tag: "Machine learning",
		homepage: true
	},
	{
		title: "DDSP: Differentiable digital signal processing",
		comment: "Scientific article",
		img: "/articles/DDSP-Article.png",
		href: "/comingsoon/",
		tag: "Machine learning",
		homepage: true
	},
	{
		title: "SP287 - What made apollo a success?",
		comment: "NASA Report",
		img: "/articles/SP287-Apollo-Article.png",
		href: "https://medium.com/@chloe.lavrat/what-should-we-learn-from-the-apollo-missions-as-engineers-e8216a6d35a0",
		tag: "Engineering",
		homepage: true
	},
//	{
//		title: "SoundStream: An End-to-End Neural Audio Codec",
//		comment: "Scientific article",
//		img: "https://placehold.co/403x771",
//		href: "",
//		tag: "Machine learning",
//		homepage: true
//	},
//	{
//		title: "Audio Spectrogram Classification using Deep Convolutional Neural Networks",
//		comment: "Scientific article",
//		img: "https://placehold.co/403x771",
//		href: "",
//		tag: "Machine learning",
//		homepage: true
//	},
];

export default articles;
