import classNames from "classnames";
import "daisyui/dist/full.css";

import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import projects from "../../data/projects";

import INFO from "../../data/user";

const filteredProjects = projects.filter(slide => slide.homepage);

const CarouselProjects = () => {
  // Function to handle left arrow click
  const scrollLeft = () => {
    const carousel = document.querySelector(".carousel");
    carousel.scrollBy({ left: -300, behavior: "smooth" });
  };

  // Function to handle right arrow click
  const scrollRight = () => {
    const carousel = document.querySelector(".carousel");
    carousel.scrollBy({ left: 300, behavior: "smooth" });
  };

  return (
    <div className="py-6 bg-gray-100 lg:h-fit min-h-screen">
      <h1 className="text-6xl font-bold text-left ml-3 pt-24 lg:mx-32">
        {INFO.homepage.projects.title}
      </h1>

      <p className="text-lg mt-9 mx-auto lg:col-span-3 lg:text-justify px-4 lg:px-0 lg:mx-32">
        {INFO.homepage.projects.description[0]}
        <span className="inline lg:block">
          {" "}{INFO.homepage.projects.description[1]}
        </span>
      </p>

      <div className="relative">
        <div className="carousel carousel-center h-3/5 py-5">
          {filteredProjects.map((slide, index) => (
            <a
              className="carousel-item rounded-2xl overflow-hidden relative lg:hover:scale-105 transition duration-300 m-3 hover:drop-shadow-md lg:first:ml-28"
              key={index}
              id={`item${index + 1}`}
              href={slide.href}
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor: slide.bgcolor }}
            >
              <div className={classNames("flex h-full justify-center", slide.centered ? "items-center mt-11" : "items-start")}>
                <img
                  src={slide.img}
                  className="rounded-2xl w-80"
                  alt={slide.title}
                />
              </div>
              <div className="absolute top-0 left-0 w-full p-8 text-white bg-opacity-50">
                <div className="font-base text-sm mb-2">
                  {slide.comment}
                </div>
                <p className="text-white font-bold text-xl">
                  {slide.title}
                </p>
              </div>
            </a>
          ))}

          <div className="lg:carousel-item lg:overflow-hidden lg:relative lg:hover:scale-105 lg:transition lg:duration-100 sm:first:ml-5 w-44">
            {/* Add any extra content here if needed */}
          </div>
        </div>

        {/* Arrow Buttons */}
        <div className="flex justify-center gap-4 mt-4">
          <button
            className="btn btn-circle btn-sm btn-neutral lg:hover:scale-110"
            onClick={scrollLeft}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            className="btn btn-circle btn-sm btn-neutral lg:hover:scale-110"
            onClick={scrollRight}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>

    </div>
  );
};

export default CarouselProjects;
