import React, { useState } from "react";
import "./styles/footer.css";

import INFO from "../../data/user";

const Footer = () => {
	const [currentYear] = useState(new Date().getFullYear());

	return (
		<React.Fragment>
			<div className="flex-grow mt-10 mb-2 "></div>
			<footer className="bg-gray-900 text-white py-6 rounded-3xl w-auto mb-2 mx-2 lg:mb-5 lg:mx-48">
				<div className="container mx-auto text-center">
					<div className="flex justify-center lg:space-x-6">
						<ul className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
							<li>
								<a href="/" className="hover:font-bold">
									Home
								</a>
							</li>
							<li>
								<a href="/projects" className="hover:font-bold">
									Projects
								</a>
							</li>
							<li>
								<a href="/articles" className="hover:font-bold">
									Articles
								</a>
							</li>
							<li className="about-me">
								<a href="/about" className="hover:font-bold">
									About me
								</a>
							</li>
						</ul>
					</div>
					<div className="mt-2 white-space: pre-wrap">
						<p>
							&copy; {INFO.main.name} {currentYear} - All rights
							reserved
						</p>
					</div>
				</div>
			</footer>
		</React.Fragment>
	);
};

export default Footer;
