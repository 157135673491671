import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";

import SEO from "../data/seo";
import INFO from "../data/user";

import articles from "../data/articles";
const categories = [...new Set(articles
    .filter(article => article.tag) // Filter out articles with no tags
    .map(article => article.tag))]; // Map to get the tag values

const slides = articles.reduce((acc, article) => {
	if (article.tag) {
		// If the tag doesn't exist in the accumulator, initialize it as an empty array
		if (!acc[article.tag]) {
			acc[article.tag] = [];
		}
		// Push the article into the corresponding tag array
		acc[article.tag].push({
			title: article.title,
			comment: article.comment,
			img: article.img,
			href: article.href,
		});
	}
	return acc;
}, {});

const Articles = () => {
	// Initialize all categories as selected by default
	const [selectedCategories, setSelectedCategories] = useState(categories);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "articles");

	const toggleCategorySelection = (category) => {
		setSelectedCategories((prevSelectedCategories) =>
			prevSelectedCategories.includes(category)
				? prevSelectedCategories.filter((item) => item !== category)
				: [...prevSelectedCategories, category]
		);
	};

	const filteredSlides = selectedCategories.length
		? Object.entries(slides)
				.filter(([key]) => selectedCategories.includes(key))
				.flatMap(([_, slideArray]) => slideArray)
		: [];

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${INFO.main.title} | articles`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>
			<NavBar active="article" />
			<div className="flex flex-col min-h-screen bg-gray-100">
				<div className="flex-grow py-1 lg:w-screen bg-gray-100">
					<h1 className="text-6xl font-bold text-left mt-24 ml-5 lg:mt-28 lg:mx-48">
						{INFO.articles.title}
					</h1>

					<h3 className="text-lg mt-9 mx-5 text-justify lg:mt-5 lg:mr-48 lg:mx-48 mb-3">
						{INFO.articles.description}
					</h3>

					<div className="px-4 lg:px-64 mt-10">
					<div className="flex flex-wrap justify-center gap-4">
						{categories.map((category, index) => (
						<button
							className={`px-6 py-3 font-semibold text-base rounded-xl ${
							selectedCategories.includes(category)
								? "bg-gray-600 text-white"
								: "bg-gray-200 text-gray-900"
							}`}
							onClick={() => toggleCategorySelection(category)}
							key={index}
						>
							#{category}
						</button>
						))}
					</div>
					</div>


					<h1 className="text-2xl font-bold text-left mt-6 ml-5 lg:mt-11 lg:mx-48 lg:mb-6">
						{selectedCategories.length > 0
							? `${selectedCategories.join(" + ")}`
							: "Select a category to view articles."}
					</h1>

					<div className="lg:mx-48 rounded-2xl">
						<div className="grid grid-cols-1 lg:grid-cols-4 gap-3 lg:gap-x-6 lg:gap-y-8 py-5 lg:m-0 mx-3">
							{filteredSlides.length > 0 ? (
								filteredSlides.map((slide, index) => (
									<a
										className="overflow-hidden relative rounded-2xl lg:hover:scale-105 transition duration-300 h-min-[650px]"
										key={index}
										href={slide.href} target="_blank" rel="noopener noreferrer"
									>
										<div className="absolute top-0 left-0 p-6 pb-12 text-white">
											<div className="font-base text-sm mb-2">
												{slide.comment}
											</div>
											<p className="text-white font-bold text-xl">
												{slide.title}
											</p>
										</div>
										<img
											src={slide.img}
											className="object-cover"
											alt={slide.title}
										/>
									</a>
							))) : (
								<p className="col-span-1 lg:col-span-4 text-center text-lg">
									No articles to display. Please select a category.
								</p>
							)}
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Articles;
