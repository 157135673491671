import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { ShaderGradient, ShaderGradientCanvas } from "shadergradient";

import INFO from "../../data/user";
import "../../pages/styles/homepage.css";

const HeroWelcomeGradient = () => {
	return (
		<div className="hero min-h-screen bg-gray-100 flex items-center justify-center bg-cover relative">
			<ShaderGradientCanvas
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					zIndex: 0,
				}}
				onCreated={({ gl }) => {
					gl.domElement.style.pointerEvents = "none";
				}}
			>
				<ShaderGradient
					enableTransition={false}
					pixelDensity={1}
					bgColor1="#6978fa"
					bgColor2="#000000"
					brightness={1.5}
					cAzimuthAngle={250}
					cDistance={15}
					cPolarAngle={140}
					cameraZoom={12.5}
					color1="#6978FA"
					color2="#ECECEC"
					color3="#FF5D73"
					envPreset="city"
					type="sphere"
					uAmplitude={7}
					uDensity={0.8}
					uFrequency={5.5}
					uSpeed={0.071}
					uStrength={3.4}
					rotationZ={140}
					positionX={0}
					positionY={0}
					positionZ={0}
					rotationX={0}
					rotationY={0}
					range={false}
					rangeEnd={0}
					rangeStart={0}
				/>
			</ShaderGradientCanvas>

			<div className="z-10">
				<div className="text-center text-white" id="mainHero">
					<h3 className="text-6xl lg:text-8xl font-bold mb-6">
						{INFO.main.name}
					</h3>
					<h1 className="text-3xl md:lg:text-3xl mb-3">
						{INFO.main.jobName}
					</h1>
					<h2 className="text-lg md:lg:text-xl mb-8">
						{INFO.main.tagline[0]}
						<span className="block md:inline">{" "}{INFO.main.tagline[1]}</span>
					</h2>
					<div className="animate-bounce mt-24">
						<ScrollLink to="expertise" smooth={true} duration={700}>
							<FontAwesomeIcon
								icon={faChevronDown}
								className="homepage-icon-white"
							/>
						</ScrollLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroWelcomeGradient;
