import React, { useEffect } from "react";

import NavBar from "../components/common/navBar";

import INFO from "../data/user";

const Notfound = () => {
	useEffect(() => {
		document.title = `404 | ${INFO.main.title}`;
	}, []);

	return (
		<React.Fragment>
			<div className="min-h-screen bg-white flex flex-col items-center justify-center bg-gray-200 relative">
				<NavBar />
				<div className="text-center">
					<div className="text-5xl lg:text-5xl font-bold p-4">
						The page you’re looking for can’t be found.{" "}
					</div>
					<div className="text-xl lg:text-xl p-6">
						We can't seem to find the page you're looking for.
						<br />
						The requested URL "{window.location.href}" was not found
						on this website.
					</div>
					<div className="mt-6 w-full flex justify-center">
						<a href="/" className="btn btn-neutral">
							Home page
						</a>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Notfound;
