import React from "react";

import Helmet from "react-helmet";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import CarouselArticles from "../components/homepage/CarouselArticles";
import CarouselProjects from "../components/homepage/CarouselProjects";
import ContactCard from "../components/homepage/ContactCard";
import ExpertiseSection from "../components/homepage/ExpertiseSection";
import HeroWelcomeGradient from "../components/homepage/HeroWelcomeGradient";
import QuoteSection from "../components/homepage/QuoteSection";
import SEO from "../data/seo";
import INFO from "../data/user";

import "fullpage.js/dist/fullpage.min.css"; // Import fullpage.js CSS
import "./styles/homepage.css";

const Homepage = () => {
	const currentSEO = SEO.find((item) => item.page === "home");

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>
			<NavBar />
			<div id="fullpage">
				{/* Sections with ids for fullpage.js */}
				<section id="welcome">
					<HeroWelcomeGradient />
				</section>
				<section id="expertise">
					<ExpertiseSection />
				</section>
				<section id="project">
					<CarouselProjects />
				</section>
				<section id="quote">
					<QuoteSection />
				</section>
				<section id="article">
					<CarouselArticles />
				</section>
				<section id="contact">
					<ContactCard />
				</section>
			</div>
			<Footer />
		</React.Fragment>
	);
};

export default Homepage;
