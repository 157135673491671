// Google Analytics Tracking ID
// https://github.com/truethari/reactfolio#-google-analytics
export const TRACKING_ID = "G-8D55E08CSF";
//<!-- Google tag (gtag.js) -->
//<script async src="https://www.googletagmanager.com/gtag/js?id=G-8D55E08CSF"></script>
//<script>
//  window.dataLayer = window.dataLayer || [];
//  function gtag(){dataLayer.push(arguments);}
//  gtag('js', new Date());
//
//  gtag('config', 'G-8D55E08CSF');
//</script>