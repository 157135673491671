const SEO = [
	{
		page: "home",
		description:
			"Chloé Lavrat | Interdisciplinary Machine Learning Engineer | Transforming complex ideas into tangible solutions with expertise in AI, computer science, and data analysis. Explore projects, articles, and expertise.",
		keywords: ["Machine Learning Engineer", "Interdisciplinary Computer Science", "AI Research Engineer", "Tech Lead", "Data Analysis", "Python", "PyTorch", "TensorFlow", "Matplotlib", "c++", "c#", "Streamlit", "Docker", "React", "Digital Signal Processing", "Natural Language Processing", "Computer Vision", "Web Development", "Innovation", "Leadership", "Problem-Solving", "Critical Thinking", "Scientific Computing", "Engineering", "Chloé", "Lavrat", "Chloé Lavrat", "Chloé Lavrat portfolio", "Interdisciplinary machine learning applications", "AI-powered scientific research", "Computer science innovation", "Data-driven problem-solving", "Python library development", "PyTorch and TensorFlow expertise", "Digital signal processing techniques", "Natural language processing applications", "Computer vision projects", "Web development with React and Streamlit", "Docker containerization", "c++ and c# programming"],
	},

	{
		page: "about",
		description:
			"About Chloé Lavrat | Machine Learning Engineer | Discover Chloé Lavrat, a machine learning engineer with a passion for innovation and problem-solving. With expertise in AI, information theory, and embedded systems, explore her journey through advanced projects and insightful articles.",
			keywords: ["Machine Learning Engineer", "Interdisciplinary Computer Science", "AI Research Engineer", "Tech Lead", "Data Analysis", "Python", "PyTorch", "TensorFlow", "Matplotlib", "c++", "c#", "Streamlit", "Docker", "React", "Digital Signal Processing", "Natural Language Processing", "Computer Vision", "Web Development", "Innovation", "Leadership", "Problem-Solving", "Critical Thinking", "Scientific Computing", "Engineering", "Chloé", "Lavrat", "Chloé Lavrat", "Chloé Lavrat portfolio", "Interdisciplinary machine learning applications", "AI-powered scientific research", "Computer science innovation", "Data-driven problem-solving", "Python library development", "PyTorch and TensorFlow expertise", "Digital signal processing techniques", "Natural language processing applications", "Computer vision projects", "Web development with React and Streamlit", "Docker containerization", "c++ and c# programming"],
	},
	{
		page: "articles",
		description:
			"Articles | Chloé | Machine Learning & AI Insights | Discover articles exploring the intersection of AI, machine learning, and practical applications. Each piece offers insights into innovative solutions for resource constraints and interdisciplinary challenges, highlighting the real-world impact of advanced technology.",
			keywords: ["Machine Learning Engineer", "Interdisciplinary Computer Science", "AI Research Engineer", "Tech Lead", "Data Analysis", "Python", "PyTorch", "TensorFlow", "Matplotlib", "c++", "c#", "Streamlit", "Docker", "React", "Digital Signal Processing", "Natural Language Processing", "Computer Vision", "Web Development", "Innovation", "Leadership", "Problem-Solving", "Critical Thinking", "Scientific Computing", "Engineering", "Chloé", "Lavrat", "Chloé Lavrat", "Chloé Lavrat portfolio", "Interdisciplinary machine learning applications", "AI-powered scientific research", "Computer science innovation", "Data-driven problem-solving", "Python library development", "PyTorch and TensorFlow expertise", "Digital signal processing techniques", "Natural language processing applications", "Computer vision projects", "Web development with React and Streamlit", "Docker containerization", "c++ and c# programming"],
	},
	{
		page: "projects",
		description:
			"Projects | Chloé | Machine Learning Engineer | Explore key projects that showcase my expertise in applying AI and data analysis to solve real-world challenges. Each project reflects my commitment to innovation and the practical impact of technology.",
			keywords: ["Machine Learning Engineer", "Interdisciplinary Computer Science", "AI Research Engineer", "Tech Lead", "Data Analysis", "Python", "PyTorch", "TensorFlow", "Matplotlib", "c++", "c#", "Streamlit", "Docker", "React", "Digital Signal Processing", "Natural Language Processing", "Computer Vision", "Web Development", "Innovation", "Leadership", "Problem-Solving", "Critical Thinking", "Scientific Computing", "Engineering", "Chloé", "Lavrat", "Chloé Lavrat", "Chloé Lavrat portfolio", "Interdisciplinary machine learning applications", "AI-powered scientific research", "Computer science innovation", "Data-driven problem-solving", "Python library development", "PyTorch and TensorFlow expertise", "Digital signal processing techniques", "Natural language processing applications", "Computer vision projects", "Web development with React and Streamlit", "Docker containerization", "c++ and c# programming"],
	},
];

export default SEO;
