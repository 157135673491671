import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation } from "react-router-dom";
import "./styles/navBar.css";

import INFO from "../../data/user";

const NavBar = (props) => {
	const location = useLocation();

	const getActiveClass = (path) => {
		return location.pathname === path ? "btn-active bg-gray-600 text-white hover:text-black" : "";
	};

	return (
		<React.Fragment>
			<div className="navbar bg-base-100 w-auto rounded-3xl m-2 lg:my-5 lg:mx-32 fixed inset-x-0 top-0 shadow-xs z-50">
				{/* Start of the navbar */}
				<div className="navbar-start">
					{/* Menus of the navbar */}
					<div className="dropdown">
						<div
							tabIndex="0"
							role="button"
							className="btn btn-ghost lg:hidden rounded-xl"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M4 6h16M4 12h8m-8 6h16"
								/>
							</svg>
						</div>
						<ul
							tabIndex="0"
							className="menu menu-md dropdown-content mt-5 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
						>
							<li>
								<a
									href="/"
									className={getActiveClass("/")}
								>
									Home
								</a>
							</li>
							<li>
								<a
									href="/projects/"
									className={getActiveClass("/projects/")}
								>
									Projects
								</a>
							</li>
							<li>
								<a
									href="/articles/"
									className={getActiveClass("/articles/")}
								>
									Articles
								</a>
							</li>
							<li>
								<a href="/" className="btn-disabled">
									·
								</a>
							</li>
							<li>
								<a
									href="/about"
									className={getActiveClass("/about/")}
								>
									About me
								</a>
							</li>
						</ul>
					</div>
					<a href="/" className="btn btn-ghost text-xl rounded-2xl">
						<FontAwesomeIcon
							icon={faCircle}
							className="homepage-icon-black mr-2"
						/>
						{INFO.main.name}
					</a>
				</div>
				<div className="navbar-center hidden lg:flex">
					{/* Horizontal menu */}
					<ul className="menu menu-horizontal px-1">
						<li className="mx-2">
							<a
								href="/"
								className={getActiveClass("/") }
							>
								Home
							</a>
						</li>
						<li>
							<a
								href="/projects/"
								className={getActiveClass("/projects/")}
							>
								Projects
							</a>
						</li>
						<li className="mx-2">
							<a
							
								href="/articles/"
								className={getActiveClass("/articles/")}
							>
								Articles
							</a>
						</li>
						<li>
							<a href="/" className="btn-disabled">
								·
							</a>
						</li>
						<li className="mx-2">
							<a
								href="/about"
								className={getActiveClass("/about")}
							>
								About me
							</a>
						</li>
					</ul>
				</div>
				{/* CTA of the navbar */}
				<div className="navbar-end">
					<a
						href={INFO.socials.linkedin.link}
						className="btn rounded-2xl"
						target="_blank"
						rel="noreferrer"
					>
						Let's connect
					</a>
				</div>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
