import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import INFO from "../../data/user";

const ContactCard = () => {
	return (
		<div className="lg:py-32 flex items-center justify-center bg-gray-800  lg:bg-white lg:mx-48">
			<div className=" bg-gray-800 text-white p-8 rounded-3xl flex flex-col md:flex-row">
				<div className="flex-1 mb-8 md:mb-0 md:mr-8">
					<h1 className="text-4xl font-bold mb-4">
						{INFO.homepage.contact.title}
					</h1>
					<h2 className="text-3xl font-bold mb-6">
						{INFO.homepage.contact.subtitle}
					</h2>
					<p className="text-xl pr-10">
						{INFO.homepage.contact.description}
					</p>
				</div>
				<div className="flex-1 bg-white p-8 rounded-xl">
					<div className="flex items-center mb-6">
						<img
							src={INFO.main.profileImage}
							alt={INFO.main.name}
							className="w-28 h-28 rounded-full mr-7"
						/>
						<div>
							<h3 className="text-2xl font-bold text-black">
								{INFO.main.name}
							</h3>
							<p className="text-gray-500 text-xl">
								{INFO.main.jobName}
							</p>
						</div>
					</div>
					<div className="mb-6">
						{Object.entries(INFO.socials).map(([key, social]) => (
							<a
								key={key}
								href={social.link}
								className="block text-black text-lg mb-2 hover:font-bold flex items-center"
							>
								<FontAwesomeIcon icon={social.icon} className="mr-2" />
								{key.charAt(0).toUpperCase() + key.slice(1)}
							</a>
						))}
					</div>
					<a
						href={INFO.homepage.contact.contactMeLink}
						className="bg-black text-white py-2 px-4 mt-16 rounded-lg hover:bg-gray-700"
					>
						Contact me
					</a>
				</div>
			</div>
		</div>
	);
};

export default ContactCard;
