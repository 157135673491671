
const projects = [
	// -------------------------------------------------------------
	// Machine Learning
	// -------------------------------------------------------------
	{
		title: "DDSP: Differentiable Digital Signal Processing.",
		comment: "PyTorch Library",
		img: "/projects/DDSP-web.png",
		href: "https://github.com/chloelavrat/torch-ddsp",
		tag: "Machine Learning",
		homepage: true
	},
	{
		title: "TorchCREPE: Pitch Estimation Model Developed in PyTorch",
		comment: "PyTorch Library",
		img: "/projects/TorchCREPE-web.png",
		href: "https://github.com/chloelavrat/torch-lenet-5",
		tag: "Machine Learning",
		homepage: true
	},
	{
		title: "STT: Turn audio files or youtube videos into text",
		comment: "Powered by Whisper",
		img: "/projects/STT-web.png",
		href: "https://github.com/chloelavrat/speech-to-text-app",
		tag: "Machine Learning",
		homepage: true,
	},
	{
		title: "Carla: Youtube Video Report Generator",
		comment: "Your personal assistants with LIama 3",
		img: "/projects/Personal-assistant-web.png",
		href: "https://github.com/chloelavrat/Personal-Assistant",
		tag: "Machine Learning",
		homepage: true
	},
	{
		title: "SP-286: The Python Perfect Library Template",
		comment: "Python Library",
		img: "/projects/SP286-web.png",
		href: "https://github.com/chloelavrat/SP-286",
		tag: "Machine Learning",
		homepage: true
	},
	{
		title: "LeNet-5: Honoring Yann LeCun's Legacy in the Realm of Neural Networks.",
		comment: "Convolutional Network Demo",
		img: "/projects/LeNet-5-web.png",
		href: "https://github.com/chloelavrat/torch-lenet-5",
		tag: "Machine Learning",
		homepage: true
	},
	// -------------------------------------------------------------
	// Synthesizers
	// -------------------------------------------------------------
	{
		title: "UNION: A Webaudio FM Synth",
		comment: "A synthesizer in the crowd with webaudio",
		img: "/projects/UNION-web.png",
		href: "https://github.com/chloelavrat/UNION-Synthesizer",
		tag: "Synthesizers",
		homepage: true
	},
	{
		title: "PowerGrid: Eurorack Power Bus",
		comment: "Distribute power",
		img: "/projects/Bus-web.png",
		//href: "https://github.com/chloelavrat/Eurorack-Power-Bus",
		href: "/comingsoon/",
		tag: "Synthesizers",
	},
	{
		title: "Beatlejuce: Credit Card Size Power Supply",
		comment: "Tiny, but powerfull",
		img: "/projects/PSU-web.png",
		//href: "https://github.com/chloelavrat/Eurorack-Tiny-Power-Supply-Unit",
		href: "/comingsoon/",
		tag: "Synthesizers",
	},
	{
		title: "Pronaos: Eurorack Midi Interface",
		comment: "Front of your eurorack system temple",
		img: "/projects/pronaos-web.png",
		href: "/comingsoon/",
		tag: "Synthesizers",
	},
	{
		title: "Gatekeeper: Eurorack Audio Adapter",
		comment: "One audio in, one audio out!",
		img: "/projects/In-Out-web.png",
		href: "/comingsoon/",
		tag: "Synthesizers",
	},
	{
		title: "Scope: Eurorack Oscillocope",
		comment: "Monitor your signals with ease",
		img: "/projects/osci-web.png",
		href: "/comingsoon/",
		tag: "Synthesizers",
	},
	{
		title: "Patch-Pals: Module Less Eurorack Circuit",
		comment: "Cable your filters",
		img: "/projects/patchpals-web.png",
		href: "/comingsoon/",
		tag: "Synthesizers",
	},
	{
		title: "NonogramQR: Hide QR-Codes in Nonograms",
		comment: "Turn text into puzzle",
		img: "/projects/Nonogram-web.png",
		href: "https://github.com/chloelavrat/NonogramQR",
		tag: "Hacking",
		homepage: true
	},

	//{
	//	title: "OSC-Performer",
	//	comment: "Where Live show Meets OSC Connectivity.",
	//	img: "/projects/OSC-Performer-web.png",
	//	href: "https://github.com/chloelavrat/osc-performer",
	//	tag: "Synthesizers",
	//	homepage: true
	//},
	{
		title: 'Open File Server',
		comment: "Share files with ease without barriers",
		img: "/projects/open-files-server-web.png",
		href: "https://github.com/chloelavrat/Open-File-Server",
		tag: "Hacking",
		homepage: true
	},
	{
		title: 'PCB Lullaby: Swing Your PCB During Etching',
		comment: "Faster PCB etching with 3D printer",
		img: "/projects/PCB-Lullaby-web.png",
		href: "https://github.com/chloelavrat/PCB-Lullaby",
		tag: "Hacking",
		homepage: true
	},
	{
		title: 'Music Downloader: Youtube and Spotify Downloader',
		comment: "Get your album locally",
		img: "/projects/music-downloader-web.png",
		href: "https://github.com/chloelavrat/Music-Downloader",
		tag: "Hacking",
		homepage: true
	},
	//{
	//	title: "Bark",
	//	comment: "From Voice to Noise to Voice",
	//	img: "/projects/Bark-web.png",
	//	href: "",
	//	tag: "Synthesizers",
	//},
	//{
	//	title: "Europi",
	//	comment: "Distribute Power",
	//	img: "/projects/europi-web.png",
	//	href: "",
	//	tag: "Synthesizers",
	//},
	//{
	//	title: "Speaker",
	//	comment: "Contribution",
	//	img: "/projects/speaker-web.png",
	//	href: "",
	//	tag: "Synthesizers",
	//},
	//{
	//	title: "Sping",
	//	comment: "bing bong",
	//	img: "/projects/Spring-web.png",
	//	href: "",
	//	tag: "Synthesizers",
	//},
];

export default projects;
