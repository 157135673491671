import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import ContactCard from "../components/homepage/ContactCard";

import SEO from "../data/seo";
import INFO from "../data/user";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentSEO = SEO.find((item) => item.page === "about");

  return (
    <React.Fragment>
      {/* SEO */}
      <Helmet>
        <title>{`${INFO.main.title} | About`}</title>
        <meta name="description" content={currentSEO.description[0]} />
        <meta name="keywords" content={currentSEO.keywords.join(", ")} />
      </Helmet>
      <NavBar active="about" />

      {/* PAGE CONTENT */}
      <div className="flex flex-col min-h-screen bg-gray-100">
        {/* Introduction Section */}
        <div className="flex flex-col lg:flex-row items-center py-10 mt-24 m-3 lg:mt-28 lg:mx-48">
          <div className="flex-1 text-left">
            <h1 className="text-5xl font-bold">Hey, I'm {INFO.main.name} 👋</h1>
            <h1 className="mt-6 text-xl font-bold">{INFO.about.subtitle}</h1>
            <div className="mt-6 text-lg text-gray-700">
              {INFO.about.description.map((paragraph, index) => (
                <p key={index} className="mb-4 last:mb-0">
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
          <div className="flex-1 mt-10 lg:mt-0 flex flex-col items-center">
            <img
              src={INFO.main.profileImage}
              alt="Profile"
              className="w-48 h-48 md:w-64 md:h-64 rounded-full shadow-lg"
            />
            <div className="mt-6 space-x-4 flex justify-center">
              {Object.entries(INFO.socials).map(([key, social]) => (
                <a
                  key={key}
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900 transition duration-150"
                >
                  <FontAwesomeIcon icon={social.icon} className="text-2xl md:text-3xl" />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Timeline Section */}
        <div className="py-12 lg:py-20 bg-white px-3 lg:px-48">
          <h2 className="text-4xl font-bold text-center mb-12">My Journey</h2>
          <div className="relative">
            {/* Timeline Bar: hidden on small screens, visible on medium and up */}
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gray-300 hidden md:block"></div>
            {[...INFO.about.experiences, ...INFO.about.education].map((item, index) => (
              <div
                key={index}
                className={`mb-8 flex items-center w-full ${
                  index % 2 === 0 ? "justify-start" : "justify-end"
                }`}
              >
                <div
                  className={`timeline-item w-full md:w-5/12 p-5 bg-gray-50 rounded-xl shadow-md flex items-center ${
                    index % 2 === 0 ? "mr-0 md:mr-5" : "ml-0 md:ml-5"
                  }`}
                >
                  {item.logo && (
                    <img
                      src={item.logo}
                      alt={`${item.title} logo`}
                      className="rounded-lg w-12 h-12 md:w-20 md:h-20 object-contain mr-4"
                    />
                  )}
                  <div>
                    <h3 className="text-xl md:text-2xl font-semibold">
                      {item.title}
                    </h3>
                    <span className="text-gray-500 text-sm">{item.date}</span>
                    <span className="text-gray-500 text-sm mx-2">•</span>
                    <span className="text-gray-500 text-sm font-bold">{item.place}</span>
                    <p className="mt-3 text-gray-700">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center bg-white py-4">
          <p className="text-center">
            <b>Photo Credits:</b>{" "}
            {INFO.about.copyright.map((item, index) => (
              <span key={index}>
                <a href={item.link} target="_blank" rel="noopener noreferrer" className="hover:font-bold mx-1">
                  {item.name}
                </a>
                ({item.type})
                {index < INFO.about.copyright.length - 1 && ", "}
              </span>
            ))}
          </p>
        </div>
      </div>
      <ContactCard />
      <Footer />
    </React.Fragment>
  );
};

export default About;
