import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link as ScrollLink } from "react-scroll";

import INFO from "../../data/user";
import "./styles/QuoteSection.css";

const QuoteSection = () => {
  const textContainerRef = useRef(null);
  const [dotSize, setDotSize] = useState(200); // Default size

  const updateDotSize = () => {
    if (textContainerRef.current) {
      const textWidth = textContainerRef.current.offsetWidth;
      setDotSize(textWidth + 50); // Add some padding to the dot size
    }
  };

  useEffect(() => {
    updateDotSize(); // Initial size
    window.addEventListener("resize", updateDotSize); // Update on resize

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDotSize);
    };
  }, []);

  return (
    <div className="hero min-h-screen bg-white flex items-center justify-center bg-cover relative">
      <div className="z-10">
        <div
          ref={textContainerRef}
          className="container mx-auto text-center fade-in-section text-white"
        >
          <h1 className="text-3xl mt-24 lg:text-5xl p-4 text-center font-bold">
            "{INFO.homepage.quote.sentence}"
          </h1>
          <h2 className="text-xl lg:text-2xl p-4 text-center font-bold">
            {INFO.homepage.quote.author}
          </h2>
          <div className="mt-10">
            <div className="animate-bounce">
              <ScrollLink to="article" smooth={true} duration={500}>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="homepage-icon-white"
                />
              </ScrollLink>
            </div>
          </div>
        </div>
      </div>
      <div
        className="blue-dot"
        style={{
          width: dotSize,
          height: dotSize,
        }}
      >
        <img
          className=""
          src="/blue-dot.png"
          alt="pale blue dot"
        />
      </div>
    </div>
  );
};

export default QuoteSection;
