import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link as ScrollLink } from "react-scroll";
import TechLogos from "./TechLogos";

import INFO from "../../data/user";

const ExpertiseSection = () => {
	return (
		<div className="min-h-screen flex flex-col justify-center bg-white ">
			<div className="flex items-center justify-center text-center">
				<div className="grid grid-cols-1 mt-12 lg:mt-16 lg:grid-cols-5 gap-8 lg:gap-0 p-12 lg:mt-24">
					<h1 className="text-5xl lg:text-6xl font-bold lg:col-span-2 place-content-center">
						{INFO.homepage.expertise.title}
					</h1>
					<p className="text-lg max-w-3xl mx-auto lg:col-span-3 lg:text-left px-4">
						I am a seasoned AI research engineer and tech lead with a specialized focus on interdisciplinary computer sciences and machine learning. 
						<span className="block md:inline">
						{" "}My passion lies in <b> transforming complex ideas </b> from diverse
						scientific disciplines
						<span className="font-semibold">
						{" "} into tangible solutions.
						</span>
						</span>			
				</p>
				</div>
			</div>
			<div className="flex items-center justify-center mx-5 text-lg lg:text-xl">
				<div className="grid grid-cols-1 sm:grid-cols-3 gap-6  text-center">
					<div className="p-6 bg-base-100 rounded-2xl bg-gray-100 transition-transform transform lg:hover:-translate-y-2 max-w-96">
						<h2 className="text-2xl lg:text-3xl font-semibold mb-2">
							{INFO.homepage.expertise.blockA.title}
						</h2>
						<p className="mb-4">
							{INFO.homepage.expertise.blockA.subtitle}
						</p>
						<p className="text-base-content">
							{INFO.homepage.expertise.blockA.description}
						</p>
					</div>
					<div className="p-6 bg-base-100 rounded-2xl bg-gray-100 transition-transform transform lg:hover:-translate-y-2 max-w-96">
						<h2 className="text-2xl lg:text-3xl font-semibold text-base-content mb-2">
							{INFO.homepage.expertise.blockB.title}
						</h2>
						<p className="text-base-content mb-4">
							{INFO.homepage.expertise.blockB.subtitle}
						</p>
						<p className="text-base-content">
							{INFO.homepage.expertise.blockB.description}
						</p>
					</div>
					<div className="p-6 bg-base-100 rounded-2xl bg-gray-100 transition-transform transform lg:hover:-translate-y-2 max-w-96">
						<h2 className="text-2xl lg:text-3xl font-semibold text-base-content mb-2">
							{INFO.homepage.expertise.blockC.title}
						</h2>
						<p className="text-base-content mb-4">
							{INFO.homepage.expertise.blockC.subtitle}
						</p>
						<p className="text-base-content">
							{INFO.homepage.expertise.blockC.description}
						</p>
					</div>
				</div>
			</div>
			<div className="">
				<TechLogos />
			</div>

			<div className="max-w-5xl mx-auto text-center">
				<div className="">
					<div className="animate-bounce">
						<ScrollLink to="project" smooth={true} duration={500}>
							<FontAwesomeIcon
								icon={faChevronDown}
								className="text-gray-800"
							/>
						</ScrollLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExpertiseSection;
