import React from "react";
import "tailwindcss/tailwind.css";
import "./styles/TechLogos.css";

import INFO from "../../data/user";

const TechLogos = () => {
	return (
		<div className="bg-white px-4 py-8 sm:px-6 lg:px-8">
			<h2 className="text-center text-2xl font-bold mb-4">
				Mastered Technology Toolbox
			</h2>
			<div className="flex justify-center lg:mx-48">
				<div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4">
					{INFO.homepage.expertise.techLogos.map((logo, index) => (
						<img
							key={index}
							src={logo.src}
							alt={logo.alt}
							className="mx-auto"
							style={{ maxWidth: "100%", height: "auto" }}
						/>
					))}
				</div>
			</div>
			<h3 className="text-center mt-5 text-lg">In action below...</h3>
		</div>
	);
};

export default TechLogos;
